import React, { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import { filterSubject, filterLevel } from "../redux/slices/blogSlice";

import BlogSection from "../components/BlogContainer";
import DifficultyButtonContainer from "../components/Buttons/DifficultyButtonContainer";
import { useLocation } from "react-router-dom"

import profilePic from "../harry-leslie-pp.png" 

export default function Home() {
  function shuffle(array) {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex != 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }
  const blogs = useSelector((state) => state.blogs);
  const dispatch = useDispatch();
  let location = useLocation();
  useEffect(() => {
    dispatch(filterSubject(""));
    dispatch(filterLevel(""));
  }, [location.pathname]);
  let blogList = blogs.blogs.map((info) => {
    return (
      <BlogSection
        Where={info.Where}
        Level={info.Level}
        Title={info.Title}
        img={info.img}
      />
    );
  });
  return (
    <div className="w-max-600 flex flex-col justify-center items-center">
      <div className="text-4xl text-center p-12">
        Hello, Welcome to my blogs, I am Harry Leslie and my goal is to get a better
        understanding of AI
      </div>
      <div>
        <div className="flex justify-center items-center my-2">
          <img
            src={profilePic}
            className="my-pp"
          />
        </div>
        <div className="text-xl py-2">
          Skills: <b>Python (Scikit-Learn, Numpy, Pandas)</b>, JavaScript,
          NodeJS, ReactJS and ExpressJS, Java, Kotlin, GitHub, CI/CD (Jenkins),
          Linux, MySQL, MongoDB
        </div>
        <div className="text-xl py-3">
          Activities: I have attended <b>the G-Research Event (In London)</b>{" "}
          with UpReach where we had to make maximum the victory points in a NFT
          auction with EDUKATE. I came second in
          the Yearly University of Southampton AI Hackathon.
        </div>
        <div className="text-xl py-3">
          {" "}
          I am part of the<b> University Of Southampton AI Society</b> Education team and the
          main Web Developer.
        </div>
        <div className="text-xl py-2">
          I started this blog in June to help me understand AI to the point
          where I am able to teach people as I think thats is the point where
          you really understand
        </div>
      </div>
      <div className="flex items-center w-full text-2xl justify-between">
        <div>My Blogs</div>
        <div className="w-4/6">
          <section className="flex justify-end">
            <DifficultyButtonContainer Level="Easy" />
            <DifficultyButtonContainer Level="Medium" />
            <DifficultyButtonContainer Level="Hard" />
          </section>
        </div>
      </div>
      <div className="flex flex-col items-center w-full">{blogList}</div>
    </div>
  );
}
