import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./global.css";
import reportWebVitals from "./reportWebVitals";

import {
  createBrowserRouter,
  Route,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import RootLayout from "./layout/RootLayout";
import Home from "./pages/Home.js";
import Mathematics from "./pages/Mathematics";
import DataProcessing from "./pages/DataProcessing";
import Models from "./pages/Models";
import Training from "./pages/Training";

import { store } from "./redux/store";

import { Provider } from "react-redux";
import MaLinearReg from "./static/blogs/mathematics/linear-regression/MaLinearReg";
import MaRidgeReg from "./static/blogs/mathematics/ridge-regression/MaRidgeReg";

import MaLassoReg from "./static/blogs/mathematics/lasso-regression/MaLassoReg";
import DpOHE from "./static/blogs/data-processing/one-hot-encoding/DpOHE";
import DpScaling from "./static/blogs/data-processing/scaling/DpScaling";
import MaDecisionTree from "./static/blogs/mathematics/decision trees/MaDecisionTree";
import DpMissingData from "./static/blogs/data-processing/missing-data/DpMissingData";
import MoLinearReg from "./static/blogs/models/MoLinearReg";
import MoXgBoost from "./static/blogs/models/MoXgBoost";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route index element={<Home />} />
      <Route path="mathematics">
        <Route index element={<Mathematics />} />
        <Route path="linear-regression" element={<MaLinearReg />} />
        <Route path="ridge-regression" element={<MaRidgeReg/>} />
        <Route path="lasso-regression" element={<MaLassoReg/>} />
        <Route path="decision-trees" element={<MaDecisionTree />} />
       </Route>
      <Route path="models" >
        <Route index element={<Models />}/>
        <Route path="linear-regression-in-python" element={<MoLinearReg />} />
        <Route path="xgboost-in-python" element={<MoXgBoost />} />
      </Route>
      <Route path="data-processing" >
        <Route index  element={<DataProcessing />} />
        <Route path="one-hot-encoding" element={<DpOHE />} />
        <Route path="scaling" element={<DpScaling />} />
        <Route path="dealing-with-missing-data" element={<DpMissingData />} />
      </Route>
      <Route path="*" element={<Home />} />
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
