import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneLight } from "react-syntax-highlighter/dist/esm/styles/prism";

import DifficultyButtonContainer from "../../../components/Buttons/DifficultyButtonContainer";

export default function MoLinearReg() {
  return (
    <div class="w-full w-max-600 my-4 bg-white">
      <div class="flex justify-center flex-col px-4">
      <div class="flex justify-between items-start">
          <div>
            <h1 class="text-3xl">Linear Regression in Python</h1>
            <h2 class="text-2xl">A Practical Guide</h2>
          </div>
          <div class="px-2 flex items-start justify-start"><DifficultyButtonContainer Level={"Easy"} /></div>
        </div>
        <div>
            <h3 className="text-xl pt-2">Introduction</h3>
          <p>
            Linear regression is a fundamental statistical technique used to
            model the relationship between variables. It finds its applications
            in various fields, including machine learning, data analysis, and
            prediction tasks. In this article, we will explore how to implement
            linear regression in Python without diving deep into the underlying
            mathematics. Instead, we'll focus on using libraries and functions
            available in Python to perform linear regression effectively.
          </p>

          <h3 class="text-xl pt-2">Using Python for Linear Regression</h3>
          <p>
            Python offers several powerful libraries that simplify the
            implementation of linear regression. One of the most widely used
            libraries is <b>sklearn</b>, which provides an easy-to-use interface
            for various machine learning algorithms, including linear
            regression.
          </p>

          <h3 className="text-xl pt-2">Import Necessary Libraries</h3>
          <p>
            Before we start, ensure you have <b>scikit-learn</b>{" "}
            installed. If not, you can install it using pip:
          </p>
          <SyntaxHighlighter language="bash" style={oneLight}>
            {`pip install scikit-learn`}
          </SyntaxHighlighter>
          <p>Now, let's import the required libraries:</p>
          <SyntaxHighlighter language="python" style={oneLight}>
            {`import numpy as np import pandas as pd 
from sklearn.linear_model import LinearRegression 
from sklearn.model_selection import train_test_split 
from sklearn.metrics import mean_squared_error,r2_score`}
          </SyntaxHighlighter>


          <h3 className="text-xl pt-2">Split the Data into Training and Testing Sets</h3>
          <p>
            To evaluate the performance of the linear regression model, we need
            to split the dataset into training and testing sets:
          </p>
          <SyntaxHighlighter language="python" style={oneLight}>
            {`X_train, X_test, y_train, y_test = train_test_split(X, y, test_size=0.2, random_state=42)`}
          </SyntaxHighlighter>

          <h3 className="text-xl pt-2">Create and Train the Linear Regression Model</h3>
          <p>
            Now, we create an instance of the <b>LinearRegression</b>{" "}
            class and fit it to the training data:
          </p>
          
            <SyntaxHighlighter language="python" style={oneLight}>
                {`model = LinearRegression() 
model.fit(X_train, y_train)`}
            </SyntaxHighlighter>


          <h3 className="text-xl pt-2">Make Predictions</h3>
          <p>
            After training the model, we can use it to make predictions on the
            test data:
          </p>
          <SyntaxHighlighter language="python" style={oneLight}>
            {`y_pred = model.predict(X_test)`}
          </SyntaxHighlighter>

          <h3 className="text-xl pt-2">Evaluate the Model</h3>
          <p>
            Finally, let's evaluate the performance of our linear regression
            model using metrics such as Mean Squared Error (MSE) and R-squared
            (R²) score:
          </p>
          <SyntaxHighlighter language="python" style={oneLight}>
            {`mse = mean_squared_error(y_test, y_pred) r2 = r2_score(y_test, y_pred) 
print("Mean Squared Error:", mse) 
print("R-squared:", r2)`}
          </SyntaxHighlighter>


          <h3 class="text-xl pt-2">Conclusion</h3>
          <p>
            In this article, we explored how to implement linear regression in
            Python using <b>scikit-learn</b>. Linear regression is a
            valuable tool for predictive modeling, and Python's libraries make
            it easy to apply the technique to real-world datasets. Remember,
            linear regression assumes a linear relationship between variables,
            so ensure that your data adheres to this assumption before using
            linear regression for modeling. Additionally, always preprocess your
            data and perform exploratory data analysis to gain insights before
            applying any machine learning algorithms.
          </p>
        </div>
      </div>
    </div>
  );
}
