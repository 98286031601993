import React from "react";
import DifficultyButtonContainer from "../../../../components/Buttons/DifficultyButtonContainer";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneLight } from "react-syntax-highlighter/dist/esm/styles/prism";

export default function DpMissingData() {
  return (
    <div className="w-full w-max-600 my-4 bg-white">
      <div className="flex justify-center flex-col px-4">
        <div className="flex justify-between items-start">
          <div>
            <h1 className="text-3xl">
              Scaling Data - Dealing with Missing Data in Data Processing
            </h1>
            <h2 className="text-2xl">Making Data Easier to Digest - Part 2</h2>
          </div>
          <div className="px-2 flex items-start justify-start">
            <DifficultyButtonContainer Level={"Easy"} />
          </div>
        </div>
        <div>
          <h3 className="text-xl pt-2">Introduction</h3>
          <p className="">
            Data preprocessing is an essential step in the machine learning
            pipeline, where raw data is transformed and prepared for model
            training. One critical aspect of data preprocessing is dealing with
            missing data. Missing data can be a common occurrence in real-world
            datasets due to various reasons such as data collection errors,
            survey non-responses, or system failures. In this article, we will
            explore different methods for handling missing data effectively to
            ensure accurate and reliable machine learning models.
          </p>

          <h3 className="text-xl pt-2">
            The Importance of Dealing with Missing Data
          </h3>
          <p>
            Missing data can introduce significant challenges in data analysis
            and modeling. If left untreated, it can lead to biased and
            inaccurate results, adversely affecting the performance of machine
            learning algorithms. Therefore, addressing missing data is crucial
            to maintain data integrity and prevent potential pitfalls in the
            modeling process.
          </p>

          <h3 className="text-xl pt-2">Methods for Dealing with Missing Data</h3>
          <p>
            There are several techniques available for handling missing data,
            and the choice of method depends on the nature of the data, the
            amount of missingness, and the specific machine learning algorithm
            being used. Let's explore some common approaches:
          </p>

          <ul>
            <li className="py-3">
              <h3 className="text-lg">Removal of Missing Data:</h3>
              <p>
                One straightforward method is to remove rows or columns that
                contain missing values. While this approach is simple, it can
                result in a loss of valuable information, especially if the
                missing data is not randomly distributed. Use this method with
                caution, and it's typically more suitable when there are many data
                samples.
              </p>
            </li>
            <li className="py-3">
              <h3 className="text-lg">Imputation Using Mean/Median:</h3>
              <p>
                Imputation involves filling in missing values with estimated
                values. A common imputation technique is to replace missing
                values in a feature with the mean or median of that feature.
                This approach maintains the original data distribution and is
                straightforward to implement. However, it may not be ideal when
                dealing with significant outliers.
              </p>
            </li>
            <li className="py-3">
              <h3 className="text-lg">Imputation Using K-Nearest Neighbors (KNN):</h3>
              <p>
                KNN imputation involves finding the K nearest data points to the
                instance with missing data and then using their values to
                estimate the missing value. This method considers the local
                neighborhood of each missing data point, making it robust to
                complex data patterns.
              </p>
            </li>
            <li className="py-3">
              <h3 className="text-lg">Multiple Imputation:</h3>
              <p>
                Multiple imputation creates multiple plausible imputations for
                each missing value and then generates several complete datasets.
                These datasets are then used for model training, and the results
                are combined to provide more robust estimates. This technique is
                particularly useful when dealing with uncertainty in the imputed
                values.
              </p>
            </li>
          </ul>

          <h3 className="text-xl">Python Code Example: Imputation By Removal</h3>
          <SyntaxHighlighter language="python" style={oneLight}>
            {`import pandas as pd 
import numpy as np 
def handle_missing_data(df):
    df_cleaned = df.dropna()
    return df_cleaned`}
          </SyntaxHighlighter>

          <h3 className="text-xl">Python Code Example: Imputation Using Mean</h3>
          <SyntaxHighlighter language="python" style={oneLight}>
            {`import pandas as pd 
import numpy as np 
def handle_missing_data(df):
    feature_means = df.mean()
    df_filled = df.fillna(feature_means) 
    return df_filled`}
          </SyntaxHighlighter>
          <h3 className="text-xl">Python Code Example: Imputation Using Median</h3> 
          <SyntaxHighlighter language="python" style={oneLight}>
            {`import pandas as pd 
import numpy as np 
def handle_missing_data(df):
    feature_median = df.median()
    df_filled = df.fillna(feature_median) 
    return df_filled`}
          </SyntaxHighlighter>

          <h3 className="text-xl">Python Code Example: Imputation Using KNN</h3> 
          <SyntaxHighlighter language="python" style={oneLight}>
            {`import pandas as pd 
import numpy as np 
def handle_missing_data(df, n):
    knn_imputer = KNNImputer(n_neighbors=n)
    df_filled = pd.DataFrame(knn_imputer.fit_transform(df), columns=df.columns)
    return df_filled`}
          </SyntaxHighlighter>
          <p className="py-2">These example are taken from my Github where using these in my Machine Learning Repo</p>
          <h3 className="text-xl">Conclusion</h3>
          <p>
            Dealing with missing data is a critical aspect of data preprocessing
            in machine learning. Failing to address missing data appropriately
            can lead to biased and inaccurate models. We explored several
            methods for handling missing data, including removal, mean/median
            imputation, KNN imputation, and multiple
            imputation.
          </p>

          <p>
            When handling missing data, it is essential to understand the nature
            of the data and the potential impact of each imputation method on
            the model's performance. By effectively managing missing data, you
            can ensure that your machine learning models are built on reliable
            and complete datasets, leading to more accurate and trustworthy
            predictions.
          </p>
        </div>
      </div>
    </div>
  );
}
