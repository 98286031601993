import React from 'react'

import { NavLink } from "react-router-dom";

export default function NavbarLink({where}) {
  return (
    <section key={`${where}`}>
    <NavLink to={`/${where.replace(/\s+/g, '-')}`.toLowerCase()}>
      <div>{where}</div>
    </NavLink>
  </section>
  )
}
