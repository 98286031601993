import MaLinearReg from "../static/blogs/mathematics/linear-regression/MaLinearReg";

const blogs = [
  {
    Title: "Linear Regression in Python",
    Level: "Easy",
    Where: "Models",
    img: "https://images.unsplash.com/photo-1515231440624-1ab5ed324ac9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80",
    component: null
  },
  {
    Title: "Linear Regression",
    Level: "Easy",
    Where: "Mathematics",
    img: "https://images.unsplash.com/photo-1515231440624-1ab5ed324ac9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80",
    component: "MaLinearReg"
  },
  {
    Title: "Ridge Regression",
    Level: "Easy",
    Where: "Mathematics",
    img: "https://images.unsplash.com/photo-1515231440624-1ab5ed324ac9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80",
    component: null,
  },
  {
    Title: "XGBoost in Python",
    Level: "Hard",
    Where: "Models",
    img: "https://images.unsplash.com/photo-1507214617719-4a3daf41b9ac?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=736&q=80",
    component: null,
  },
  {
    Title: "Lasso Regression",
    Level: "Medium",
    Where: "Mathematics",
    img: "https://images.unsplash.com/photo-1633164442172-dc4147f21954?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1332&q=80",
    component: null
  },
  {
    Title: "One Hot Encoding",
    Level: "Easy",
    Where: "Data Processing",
    img: "https://images.unsplash.com/photo-1572615318109-4b6595947181?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTc1fHxhYnN0cmFjdCUyMGxpbmVzfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60",
    component: null
  },
  {
    Title: "Decision Trees",
    Level: "Medium",
    Where: "Mathematics",
    img: "https://images.unsplash.com/photo-1531993268000-a3fc2af2e9a9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
    component: null,
  },
  {
    Title: "Scaling",
    Level: "Easy",
    Where: "Data Processing",
    img: "https://images.unsplash.com/photo-1531993268000-a3fc2af2e9a9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
    component: null
  },
  {
    Title: "Dealing With Missing Data",
    Level: "Easy",
    Where: "Data Processing",
    img: "https://images.unsplash.com/photo-1637070144361-0224cb0f741a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjQwfHxhYnN0cmFjdCUyMGxpbmVzfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60",
    component: null
  },
];

export default blogs;
