import React, { useEffect } from "react";
import img1 from "./part1.png";
import img2 from "./part2.png";
import img3 from "./part3.png";
import img4 from "./part4.png";
import img5 from "./part5.png";
import img6 from "./part6.png";
import img7 from "./part7.png";

import BlogImageContainer from "../../../../components/BlogImageContainer";
import DifficultyButtonContainer from "../../../../components/Buttons/DifficultyButtonContainer";


export default function MaRidgeReg() {
  return (
<div className="w-full w-max-600 my-4 bg-white">
  <div className="flex justify-center flex-col px-4">
    <div className="flex justify-between items-start">
      <div>
        <h1 className="text-3xl">
          Ridge Regression - Mathematics
        </h1>
        <h2 className="text-2xl">Regularising Linear Models</h2>
      </div>
      <div className="px-2 flex items-start justify-start">
        <DifficultyButtonContainer Level={"Easy"} />
      </div>
    </div>
    <div>
      <h3 className="text-xl">Introduction</h3>
      <p className="py-2">
        Ridge Regression is an extension of linear regression, a powerful
        statistical technique that allows us to model the relationship between
        variables. While linear regression works well when the data is abundant
        and well-behaved, it can suffer from overfitting in the presence of
        multicollinearity and noisy data. Ridge Regression is a technique that
        addresses this issue by introducing a regularisation term that adds a
        penalty for large coefficient values. In this blog, we will explore the
        mathematics behind Ridge Regression, understand its motivation, and see
        how it can be a valuable tool in machine learning and data analysis.
      </p>
      <p className="py-2">
        At its core, Ridge Regression aims to find the best-fitting line that
        represents the relationship between the independent variables and the
        dependent variable. However, in addition to minimising the error between
        the real and predicted Y values, Ridge Regression also includes a
        regularisation term (λ) multiplied by the sum of squared coefficients
        in the error function:
      </p>
      <BlogImageContainer img={img1} alt={"Ridge Regression Equation"} />

      <h3 className="text-xl">The Need for Regularisation</h3>
      <p>
        In ordinary linear regression, the model can become overly complex when
        the number of features is high, leading to a phenomenon called
        multicollinearity. Multicollinearity occurs when two or more independent
        variables are highly correlated, making it difficult for the model to
        distinguish their individual effects on the dependent variable. As a
        result, the model tends to overfit, capturing noise instead of the
        underlying patterns.
      </p>

      <p className="py-2">
        Not only that but if a data set has got too many collinearity it would
        not be possible to solve the inverse meaning you would can not use the
        closed form solution for ordinary linear regression. Leading you to use other
        techniques like gradient descent
      </p>
      <p className="py-2">
        The regularisation term in Ridge Regression, controlled by the
        hyperparameter λ, penalises large coefficients, encouraging the model
        to find a balance between fitting the data and keeping the coefficients
        small. By adding this penalty, Ridge Regression can mitigate the impact
        of multicollinearity and reduce the risk of overfitting, producing more
        reliable and stable predictions.
      </p>

      <h3 className="text-xl pt-4">Solving Ridge Regression</h3>
      <p>
        To find the coefficients that minimise the error function, we still use
        ordinary least squares (OLS). However, this time, we include the
        regularisation term in the equation. This is in vector notation
      </p>
      <BlogImageContainer img={img2} alt={""} />
      <p>Then if you expand this the brackets to get</p>
      <BlogImageContainer img={img3} />
      <p>Then you can simplify to</p>
      <BlogImageContainer img={img4} />
      <p>
        The closed-form solution for Ridge Regression involves taking the
        derivative of the error function with respect to the coefficients (β)
      </p>
      <BlogImageContainer img={img5} alt={""} />
      <p>
        setting it to zero
      </p>
      <BlogImageContainer img={img6} />
      <p>
        and solving for β.
      </p>
      <BlogImageContainer img={img7} />
      <p>
        The derivation is similar to that of ordinary
        linear regression but includes the regularisation term:
      </p>
      <p>
        Solving this equation gives us the coefficients (β) that minimise the
        regularised error function and provide the best-fitting line for Ridge
        Regression.
      </p>

      <h3 className="text-xl pt-4">
        Choosing the Regularisation Parameter (λ)
      </h3>
      <p>
        The choice of the regularisation parameter (λ) is critical in Ridge
        Regression. If λ is too large, the penalty for large coefficients will
        dominate the error function, resulting in underfitting. On the other
        hand, if λ is too small, the regularisation effect is minimal, and the
        model may still overfit the data. Therefore, it is essential to use
        techniques like cross-validation to find the optimal value of λ that
        balances the trade-off between bias and variance, leading to a model
        that generalises well to unseen data.
      </p>

      <h3 className="text-xl pt-4">Conclusion</h3>
      <p>
        Ridge Regression is a powerful technique to handle multicollinearity
        and overfitting in linear regression. By introducing a regularisation
        term that penalises large coefficients, Ridge Regression helps produce
        more robust and reliable models. It is widely used in various
        applications, from finance to healthcare, where data often suffers from
        high dimensionality and noisy features. The ability to fine-tune the
        regularisation parameter (λ) makes Ridge Regression a valuable tool for
        building predictive models that perform well on both training and
        testing data.
      </p>
    </div>
  </div>
</div>

  );
}
