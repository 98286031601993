import React, { useEffect } from "react";

import img1 from "./part1.png";
import img2 from "./part2.png";
import img3 from "./part3.png";
import img4 from "./part4.png";
import img5 from './part5.png';
import img6 from './part6.png';
import img7 from './part7.png';
import img8 from './part8.png';
import img9 from './part9.png';
import img10 from './part10.png';
import img11 from './part11.png';
import img12 from './part12.png';
import img13 from './part13.png';
import img14 from './part14.png';

import BlogImageContainer from "../../../../components/BlogImageContainer";
import DifficultyButtonContainer from "../../../../components/Buttons/DifficultyButtonContainer";
import { useLocation } from "react-router-dom";

export default function MaLinearReg() {
  return (
    <div className="w-full w-max-600 my-4 bg-white">
      <div className="flex justify-center flex-col px-4">
        <div className="flex justify-between items-start">
          <div>
            <h1 className="text-3xl">Linear Regression - Mathematics</h1>
            <h2 className="text-2xl">Predicting with Lines</h2>
          </div>
          <div className="px-2 flex items-start justify-start">
            <DifficultyButtonContainer Level={"Easy"} />
          </div>
        </div>
        <div>
        <h3 className="text-xl">Introduction</h3>
          <p className="py-2">
            Linear regression is a powerful and widely-used statistical
            technique that allows us to model the relationship between two or
            more variables. It serves as a fundamental tool in the field of
            machine learning and data analysis. In this blog, we will delve into
            the mathematics behind linear regression, exploring its core
            concepts, assumptions, and how it enables us to make predictions
            using simple straight lines.
          </p>
          <p className="py-2">
            At its core, linear regression aims to find the best-fitting line
            that represents the relationship between the independent variable(s)
            and the dependent variable. The equation of this line is given by:
          </p>
          <BlogImageContainer img={img1} alt={"Linear Regression Equation"} />
          <h3 className="text-xl">The Basic Idea</h3>
          <p>
            Here, y represents the dependent variable, x are the independent
            variables. β₀ is the intercept (the value of Y when all X variables
            are 0). β₁, β₂, ..., βₚ are the coefficients for X₁, X₂, ..., Xₚ,
            respectively.
          </p>
          <p>
            Now with Linear Regression normally have more than one data point
            that you would use to train the model which is why matrices are used
            to show all of.
          </p>
          <BlogImageContainer img={img2} alt={"Matrix For Linear Regression"} />
          <p>
            and the β values would be put in a vector that we are trying to
            predict. This is vector has all the coefficents that you will
            multiple X values with.
          </p>
          <BlogImageContainer img={img3} alt={"Vector of coefficents"} />
          <p>With this we can now put this into a better form</p>
          <BlogImageContainer img={img4} alt={""} />
          <p>
            where each y value in the vector represents each row in the after
            the matrix multiplication has happened. Now want to find out what β
            values allows to have the line of best fit. To do that we need to
            minimise the error between the real Y values and the predicted Y
            values from our matrix.
          </p>
          <h3 className="text-xl pt-4">Finding Coefficents</h3>
          <p>
          To find the minimum error there are many technique from numerical
          methods. In this case we are going to be using ordinary least squares
          (OLS). To do this we use a error function, in this case we are using squared
          error. the reason we are using squared error is firstly it only
          considers the error look at which direction the error is as the error
          can be both positive and negative. In that case we can take the sum of
          all the errors together to find the total mean squared error. This error
          is calculated by taking the actual Y and subtracting it from the models Y.
          In this case the model Y looks like this
          </p>
          <BlogImageContainer img={img5} alt={""} />
          <p>
            So to find the error we take the sum of the actual Y minus Y-Hat sqaured for all points. This gives us the 
            total sqaured error
          </p>
          <BlogImageContainer img={img6} />

          <p>
            We can put this into vector form by using vectors to represent all the y and y-Hat values
          </p>
          <BlogImageContainer img={img7} />
          <p>
            And because containing y-Hat is equal to Xβ we can substitute that in 
          </p>
          <BlogImageContainer img={img8} />
          <p>
            Now to allow for a value closed form solution we need to use multiple with the transpose as if you just use
            this you will get matrix multiplication issues
          </p>
          <BlogImageContainer img={img9} />
          <p>
            This allows us to get the to the corrected form of 
          </p>
          <BlogImageContainer img={img10} />
          <p>
            You can now expand this as normal and you will get
          </p>
          <BlogImageContainer img={img11} />
          <p>
            Finally if you take partial derivative of this in terms of Beta, you will get what is needed to solve the closed form. now differentiating by a vector 
            seems insane but you can do it. I will explain how exactly this works in later blogs for Mathematics but for now
            just trust me that you will get this 
          </p>
          <BlogImageContainer img={img12} />
          <p>
            Now making this equal to 0 will allow us to find the minimum of the error function, remember we know its a minimum because a positive quadratic 
            has one minimum. We know it will be a positive quadratic because we took the squared error which is in the quadratic shape. If we also take out
            the factor of 2 and move the X^Ty to the other side you get
          </p>
          <BlogImageContainer img={img13} />
          <p>
            And then you take the inverse of X^TX on both sides you will get 
          </p> 
          <BlogImageContainer img={img14} />
          <p>
            And finally you will get this in its finally form which is what is partly what is needed to solve Linear Regression problems but you will see an issues
            because there is a inverse the data points you put it must not have multicolinearity or else there will not be an inverse. This is where methods like 
            Ridge Regression, Lasso Regression and using Gradient Descent to find the vector of coefficents needed. There are a bunch of techniques here but I have
            only shown you the closed form solution
          </p>
        </div>
      </div>
    </div>
  );
}
