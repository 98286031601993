import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { filterLevel } from "../../../redux/slices/blogSlice";

export default function DifficultyButtonContainer({ Level }) {
  const dispatch = useDispatch();
  const blogs = useSelector((state) => state.blogs)
  return (
    <div
      className={`${Level.toLowerCase()} 
      cursor-pointer max-w-60 text-center rounded-md my-2 mx-2 p-2
      ${(blogs.level && blogs.level.toLowerCase() !== Level.toLowerCase()) ? 'darken-gray' : ''}
      `}
      onClick={() => dispatch(filterLevel(Level))}
    >
      <div className="white-font text-base">{Level}</div>
    </div>
  );
}
