import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneLight } from "react-syntax-highlighter/dist/esm/styles/prism";

import DifficultyButtonContainer from "../../../components/Buttons/DifficultyButtonContainer";

export default function MoXgBoost() {
  return (
    <div class="w-full w-max-600 my-4 bg-white">
      <div class="flex justify-center flex-col px-4">
        <div class="flex justify-between items-start">
          <div>
            <h1 class="text-3xl">XGBoost in Python</h1>
            <h2 class="text-2xl">A Practical Guide</h2>
          </div>
          <div class="px-2 flex items-start justify-start"><DifficultyButtonContainer Level={"Hard"} /></div>
        </div>
        <div>
        <h3 className="text-xl">Introduction</h3>
          <p>
            XGBoost (Extreme Gradient Boosting) is a powerful machine learning
            algorithm that has gained widespread popularity for its exceptional
            performance in various data science competitions and real-world
            applications. It is an implementation of the gradient boosting
            framework, which involves combining multiple weak learners
            (typically decision trees) to create a strong predictive model. In
            this article, we will explore how to use XGBoost in Python and
            demonstrate its effectiveness in solving regression and
            classification problems.
          </p>

          <h3 class="text-xl pt-2">Using Python for XGBoost</h3>
          <p>
            Python provides several libraries for working with XGBoost. One of
            the most popular and widely used libraries is the <b>xgboost</b>{" "}
            library, which is an efficient and scalable implementation of the
            XGBoost algorithm. To get started, make sure you have XGBoost
            installed in your Python environment:
          </p>
          <SyntaxHighlighter language="bash" style={oneLight}>
            {`pip install xgboost`}
          </SyntaxHighlighter>
          <p>Next, import the necessary libraries:</p>
          <SyntaxHighlighter language="python" style={oneLight}>
            {`import numpy as np
import pandas as pd
import xgboost as xgb
from sklearn.model_selection import train_test_split
from sklearn.metrics import mean_squared_error, r2_score`}
          </SyntaxHighlighter>

          <h3 class="text-xl pt-2">
            Split the Data into Training and Testing Sets
          </h3>
          <p>
            As with any machine learning task, we need to divide our dataset
            into training and testing sets to evaluate the performance of the
            XGBoost model:
          </p>
          <SyntaxHighlighter language="python" style={oneLight}>
            {`X_train, X_test, y_train, y_test = train_test_split(X, y, test_size=0.2, random_state=42)`}
          </SyntaxHighlighter>

          <h3 class="text-xl pt-2">Create and Train the XGBoost Model</h3>
          <p>
            The first step is to create an instance of the XGBoost regressor or
            classifier, depending on the type of problem you are solving. Then,
            fit the model to the training data:
          </p>
          <SyntaxHighlighter language="python" style={oneLight}>
            {`# For Regression
model = xgb.XGBRegressor()
model.fit(X_train, y_train)

# For Classification
model = xgb.XGBClassifier()
model.fit(X_train, y_train)`}
          </SyntaxHighlighter>

          <h3 class="text-xl pt-2">Make Predictions</h3>
          <p>
            After training the XGBoost model, we can use it to make predictions
            on the test data:
          </p>
          <SyntaxHighlighter language="python" style={oneLight}>
            {`y_pred = model.predict(X_test)`}
          </SyntaxHighlighter>

          <h3 class="text-xl pt-2">Evaluate the Model</h3>
          <p>
            To assess the performance of the XGBoost model, we can use various
            metrics such as Mean Squared Error (MSE) and R-squared (R²) score
            for regression tasks:
          </p>
          <SyntaxHighlighter language="python" style={oneLight}>
            {`mse = mean_squared_error(y_test, y_pred)
r2 = r2_score(y_test, y_pred)

print("Mean Squared Error:", mse)
print("R-squared:", r2)`}
          </SyntaxHighlighter>

          <h3 class="text-xl pt-2">Conclusion</h3>
          <p>
            In this article, we explored how to use XGBoost in Python, a
            powerful machine learning algorithm known for its high performance
            and flexibility. XGBoost is widely used in various data science
            tasks, including regression and classification problems. By
            following the steps outlined in this guide, you can harness the full
            potential of XGBoost to build accurate predictive models for your
            own datasets. However, like any machine learning algorithm, it's
            essential to preprocess your data, handle missing values, and
            perform feature engineering to achieve the best results.
            Additionally, hyperparameter tuning can further enhance the model's
            performance. Always remember to validate your model's results and
            experiment with different settings to find the best configuration
            for your specific problem.
          </p>
        </div>
      </div>
    </div>
  );
}
