import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneLight } from "react-syntax-highlighter/dist/esm/styles/prism";
import DifficultyButtonContainer from "../../../../components/Buttons/DifficultyButtonContainer";
export default function DpOHE() {
  const codeString = `import pandas as pd

    data = { 'Fruit': ['apple', 'banana', 'orange', 'apple', 'orange', 'banana'] }
    df = pd.DataFrame(data)
    
    one_hot_encoded = pd.get_dummies(df['Fruit'], prefix='Fruit')
    
    df_encoded = pd.concat([df, one_hot_encoded], axis=1)
    
    df_encoded.drop('Fruit', axis=1, inplace=True)
    
    print(df_encoded)`;
  return (
    <div class="w-full w-max-600 my-4 bg-white">
      <div class="flex justify-center flex-col px-4">
        <div class="flex justify-between items-start">
          <div>
            <h1 class="text-3xl">One Hot Encoding - Data Processing</h1>
            <h2 class="text-2xl">A Crucial Step in Data Preprocessing</h2>
          </div>
          <div class="px-2 flex items-start justify-start"><DifficultyButtonContainer Level={"Easy"} /></div>
        </div>
        <div>
          <h3 class="text-xl pt-3">What is One-Hot Encoding?</h3>
          <p>
            One-hot encoding is a process of converting categorical variables
            into a binary representation, where each category is transformed
            into a new binary feature column. Each binary feature column
            indicates the presence or absence of a specific category for a given
            observation. This representation allows machine learning models to
            treat each category as a distinct and independent feature, enabling
            them to process categorical data effectively.
          </p>

          <h3 class="text-xl">
            Python Code Example: One-Hot Encoding with pandas
          </h3>
          <SyntaxHighlighter language="python" style={oneLight}>
            {codeString}
          </SyntaxHighlighter>
          <p>
            In the very simple example above, the Fruit column was one-hot
            encoded into three binary feature columns: "Fruit_apple,"
            "Fruit_banana," and "Fruit_orange." Each row now represents a binary
            vector, indicating the presence 1 or absence 0 of a specific fruit
            category. This gives an output of:
          </p>
          <SyntaxHighlighter language="python" style={oneLight}>
            {`   Fruit_apple  Fruit_banana  Fruit_orange
0            1             0             0
1            0             1             0
2            0             0             1
3            1             0             0
4            0             0             1
5            0             1             0
`}
          </SyntaxHighlighter>

          <h3 className="text-xl">
            Why is One-Hot-Encodig Used For Features and Not Labelled Encoding
          </h3>
          <p>
            I have heard people use Label Encoding for the features in a model.
            You should not use label encoding for features as a model might find
            bias because the model is trying to find a pattern due to the
            ordered nature of the numbers. This leads to numerical biases
            leading to incorrect interpretations and predictions in some machine
            learning algorithms. You should really only use Label Encoding when
            you are encoding the output vector
          </p>

          <h3 class="text-xl pt-3">Categorical Encodings Beyond One-Hot Encoding</h3>
          <p>
            Although one-hot encoding is widely used and effective for many
            scenarios, it is not the only categorical encoding technique
            available. Other techniques, such as target encoding, frequency
            encoding, and binary encoding, can also be considered based on the
            nature of the data and the specific problem at hand. These
            alternative encodings can help in cases where the number of
            categories is large, and one-hot encoding might not be the most
            efficient choice.
          </p>

          <h3 className="text-xl pt-3">Dummy Variable Trap</h3>
          <p>
            The "Dummy Variable Trap" is a common issue that can arise when
            using one-hot encoding. It occurs when one feature column can be
            predicted perfectly from the others. In other words, one feature
            column becomes a linear combination of the other columns, leading to
            multicollinearity in the data. To avoid the dummy variable trap, one
            of the binary feature columns for each categorical variable should
            be dropped. This means using "n-1" columns for "n" categories in
            one-hot encoding. Dropping one column ensures linear independence
            among the features.
          </p>

          <h3 class="text-xl pt-3">Conclusion</h3>
          <p>
            One-hot encoding is a powerful technique for converting categorical
            variables into a suitable format for machine learning algorithms. By
            using binary feature columns, one-hot encoding enables models to
            process categorical data effectively. Python, with libraries like
            pandas, provides convenient tools to perform one-hot encoding
            effortlessly. Remember to consider how to handle new categories in
            unseen data to ensure your model's robustness and accuracy.
          </p>
          <p>
            In summary, one-hot encoding is a crucial step in the data
            preprocessing pipeline when working with categorical variables, and
            understanding its implementation can significantly improve the
            performance of your machine learning models.
          </p>
        </div>
      </div>
    </div>
  );
}
