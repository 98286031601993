import React from "react";
import { NavLink } from "react-router-dom";
import ImageContainer from "../ImageContainer";
import DifficultyButtonContainer from "../Buttons/DifficultyButtonContainer";
export default function BlogSection({ Title, Level, Where, img }) {
  return (
    <article className="my-2 w-full">
      <div className="bg-white">
        <NavLink to={`/${Where.replace(/\s+/g, "-")}/${Title.replace(/\s+/g, "-")}`.toLowerCase()}>
          <div className="flex">
            <div>
              <ImageContainer src={img} />
            </div>
            <div className="w-full px-2">
              <section className="text-xl">
                {Title} - {Where}
              </section>
              <DifficultyButtonContainer Level={Level} />
            </div>
          </div>
        </NavLink>
      </div>
    </article>
  );
}
