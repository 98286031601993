import { createSlice } from "@reduxjs/toolkit";
import blogs from "../../blogs/blogs";
const initialState = {
  blogs: blogs,
  level: "",
  subject: "",
};
export const blogSlice = createSlice({
  name: "blogSlice",
  initialState,
  reducers: {
    filterLevel: (state, action) => {
      state.blogs = blogs;
      if (state.level === action.payload) {
        state.level = "";
      } else {
        state.level = action.payload;
        state.blogs = state.blogs.filter((key) => {
          if (
            state.level &&
            state.level.toLowerCase() !== key.Level.toLowerCase()
          ) {
            return false;
          }

          if (
            state.subject &&
            state.subject.toLowerCase() !== key.Where.toLowerCase()
          ) {
            return false;
          }
          return true;
        });
      }
    },
    filterSubject: (state, action) => {
      state.subject = action.payload;
      state.blogs = state.blogs.filter((key) => {
        if (
          state.level &&
          state.level.toLowerCase() !== key.Level.toLowerCase()
        ) {
          return false;
        }

        if (
          state.subject &&
          state.subject.toLowerCase() !== key.Where.toLowerCase()
        ) {
          return false;
        }
        return true;
      });
    },
  },
});
export const { filterLevel, filterSubject } = blogSlice.actions;
export default blogSlice.reducer;
