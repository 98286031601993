import React, { useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";

import { filterSubject, filterLevel } from '../../redux/slices/blogSlice';

import BlogSection from '../../components/BlogContainer';
import DifficultyButtonContainer from '../../components/Buttons/DifficultyButtonContainer';

import { useLocation } from "react-router-dom";
export default function DataProcessing() {
  const blogs = useSelector((state) => state.blogs);
  const dispatch = useDispatch();
  let location = useLocation();
  useEffect(()=>{
      dispatch(filterSubject("Data Processing"))
      dispatch(filterLevel(""))
  }, [location.pathname])
  let blogList = blogs.blogs.map((info)=>{
      return (
          <BlogSection Where={info.Where} Level={info.Level} Title={info.Title} img={info.img}/>
      )
  })
  useEffect(()=>{
      dispatch(filterSubject("Data Processing"))
  }, [blogs.level])
return (
  <div className="w-max-600 flex flex-col justify-center items-center">
    <div className="text-4xl text-center p-12">
    Mastering Data Processing: Unleashing the Power of Information
    </div>
    <div className="flex items-center w-full text-2xl justify-between">
      <div>
          My Blogs
      </div>
      <div className="w-4/6">
          <section className="flex justify-end">
              <DifficultyButtonContainer Level="Easy" />
              <DifficultyButtonContainer Level="Medium" />
              <DifficultyButtonContainer Level="Hard" />
          </section>
      </div>
    </div>
    <div className="flex flex-col items-center w-full">
      {blogList} 
    </div>
  </div>
)
}
