import React from "react";

import { NavLink } from "react-router-dom";

import NavbarLink from "../NavbarLink";

export default function Navbar() {
  const NavLinks = ["Mathematics", "Models", "Data Processing"];
  const Links = NavLinks.map((key, idx) => {
    return (<NavbarLink where={key}/>)
    }
  );
  return (
    <header className="flex justify-center items-center w-full text-xl bg-white h-12 mobile-text-sm ">
      <nav className="flex w-full justify-between px-12 mobile-center">
        <div className="w-200 text-center">
          <main className="w-full">
            <NavLink to={"/"}>
              <div>AI with Harry</div>
            </NavLink>
          </main>
        </div>
        <div className="flex justify-between w-4/6 max-w-xl mobile-hidden">{Links}</div>
      </nav>
    </header>
  );
}
