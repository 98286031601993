import {Outlet, useLocation} from 'react-router-dom'

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import React, { useEffect } from 'react'

export default function RootLayout() {
    let {pathname} = useLocation()
    useEffect(()=>{
      window.scrollTo(0, 0);
    }, [pathname])

  return (
    <>
        <Navbar />
        <main className='flex justify-center items-center px-2 min-vh'>
            <Outlet />
        </main>
        <Footer />
    </>
  )
}
