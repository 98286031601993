import React from "react";
import img1 from "./part1.png";
import img2 from "./part2.png";
import img3 from "./part3.png";
import img4 from "./part4.png";
import img5 from "./part5.png";
import img6 from "./part6.png"

import BlogImageContainer from "../../../../components/BlogImageContainer";
import DifficultyButtonContainer from "../../../../components/Buttons/DifficultyButtonContainer";
export default function MaDecisionTree() {
  return (
    <div className="w-full w-max-600 my-4 bg-white">
      <div className="flex justify-center flex-col px-4">
        <div className="flex justify-between items-start">
          <div>
            <h1 className="text-3xl">Decision Trees - Mathematics</h1>
            <h2 className="text-2xl">
              A Powerful Tool for Classification and Regression
            </h2>
          </div>
          <div className="px-2 flex items-start justify-start">
            <DifficultyButtonContainer Level={"Medium"} />
          </div>
        </div>
        <div>
          <h3 className="text-xl">Introduction</h3>
          <p className="py-2">
            Decision Trees are versatile and widely used machine learning
            algorithms that excel in both classification and regression tasks.
            They are part of the supervised learning paradigm, where the
            algorithm learns from labeled training data to make predictions on
            new, unseen data. Decision Trees work by recursively splitting the
            input space into regions that correspond to different classes or
            numerical values, creating a tree-like structure of decisions and
            outcomes. This article will explore the mathematics behind Decision
            Trees, how they make decisions, and why they are valuable tools for
            solving complex problems in various domains.
          </p>
          <h3 className="text-xl">The Mathematics of Decision Trees</h3>
          <p>
            At the core of a Decision Tree is the process of selecting the best
            features to split the data and create decision nodes. The algorithm
            evaluates each feature's ability to discriminate between classes or
            predict numerical values based on certain criteria, such as Gini
            impurity or information gain.
          </p>

          <p className="py-2">
            Gini impurity is a measure of how often a randomly chosen element
            from the set would be incorrectly classified if it was randomly
            labeled according to the distribution of labels in the set. The Gini
            impurity index is given by the formula:
          </p>
          <BlogImageContainer img={img1} alt={"Gini Impurity Formula"} />

          <p>
            Information gain, on the other hand, is based on the concept of
            entropy, which measures the uncertainty or disorder in a set of
            data. The information gain is calculated by comparing the entropy of
            the parent node with the weighted average of the entropies of its
            child nodes after splitting on a particular feature. The formula for
            information gain is as follows:
          </p>
          <BlogImageContainer img={img2} alt={"Information Gain Formula"} />

          <p className="py-2">
            The Decision Tree algorithm iterates through each feature,
            calculating either the Gini impurity or information gain for each
            possible split. It then selects the feature that results in the
            highest impurity reduction or information gain. This process is
            repeated recursively for each subset of data created by the splits
            until a stopping criterion is met, such as a maximum tree depth or a
            minimum number of samples per leaf.
          </p>
          <h3 className="text-xl pt-4">Handling Overfitting</h3>
          <p>
            Decision Trees have the potential to become very complex and overfit
            the training data, capturing noise instead of general patterns. To
            mitigate overfitting, regularization techniques such as setting a
            maximum depth, pruning, or using a minimum number of samples per
            leaf node are employed. These techniques prevent the tree from being
            too specific to the training data and help it generalize well to
            new, unseen data.
          </p>

          <h3 className="text-xl pt-4">Decision Trees for Regression</h3>
          <p>
            Decision Trees can be used for regression tasks as well. Instead of
            predicting discrete classes, the algorithm predicts a continuous
            numerical value for the target variable. The process of splitting
            and finding the best features is similar to classification, but
            instead of Gini impurity or information gain, regression trees use
            metrics like mean squared error or mean absolute error to evaluate
            the quality of the splits.
          </p>
          <p>
            The mean squared error (MSE) for a split is given by the formula:
          </p>
          <BlogImageContainer img={img6} alt={"Mean Squared Error Formula"} />

          <p className="py-2">
            Decision Trees for regression are particularly useful when the
            relationship between the features and the target variable is
            non-linear and may involve complex interactions between variables.
            The tree's hierarchical structure allows it to capture these
            intricate relationships and make accurate predictions on unseen
            data.
          </p>
          <h3 className="text-xl pt-4">Conclusion</h3>
          <p>
            Decision Trees are powerful and interpretable machine learning
            algorithms that play a significant role in both classification and
            regression tasks. By recursively making decisions based on the best
            features, Decision Trees can efficiently split the data space and
            create predictive models that can handle complex relationships and
            make accurate predictions. However, care must be taken to avoid
            overfitting, and regularization techniques are often employed to
            ensure the trees generalize well to unseen data. Decision Trees find
            applications in various fields, including finance, healthcare, and
            natural language processing, making them a fundamental tool in the
            data scientist's arsenal.
          </p>
        </div>
      </div>
    </div>
  );
}
