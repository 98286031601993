import React from "react";
import FooterLink from "../FooterLinks";

export default function Footer() {
  const NavLinks = ["Mathematics", "Models", "Data Processing"];
  const Links = NavLinks.map((key, idx) => {
    return <FooterLink where={key} />;
  });
  return (
    <div>
      <div className="flex flex-col w-full justify-center mt-6 bg-white h-fit items-center text-lg">
        {new Date().getFullYear()} copyright @ AI with Harry
        <div className="flex justify-center mt-2 mobile-flex-col">{Links}</div>
      </div>
    </div>
  );
}
