import React, { useEffect } from "react";
import img1 from "./part1.png";
import img2 from "./part2.png";
import img3 from "./part3.png";
import img4 from "./part4.png";
import img5 from "./part5.png";

import BlogImageContainer from "../../../../components/BlogImageContainer";
import DifficultyButtonContainer from "../../../../components/Buttons/DifficultyButtonContainer";
import { useLocation } from "react-router-dom";

export default function MaLassoReg() {
  return (
    <div className="w-full w-max-600 my-4 bg-white">
      <div className="flex justify-center flex-col px-4">
        <div className="flex justify-between items-start">
          <div>
            <h1 className="text-3xl">
              Lasso Regression - Mathematics
            </h1>
            <h2 className="text-2xl">A Solution to Feature Selection</h2>
          </div>
          <div className="px-2 flex items-start justify-start">
            <DifficultyButtonContainer Level={"Medium"} />
          </div>
        </div>
        <div>
          <h3 className="text-xl">Introduction</h3>
          <p className="py-2">
            Lasso Regression, short for Least Absolute Shrinkage and Selection
            Operator, is another extension of linear regression that addresses
            some limitations of traditional linear regression. While linear
            regression can be powerful, it may suffer from overfitting and
            multicollinearity when dealing with high-dimensional data containing
            many correlated features. Lasso Regression introduces a
            regularisation term like Ridge Regression, but with a subtle
            difference, to achieve feature selection and produce simpler and
            more interpretable models.
          </p>
          <p className="py-2">
            In this article, we will delve into the workings of Lasso
            Regression, understand the motivation behind its formulation, and
            explore how it can effectively handle complex datasets, making it a
            valuable tool in modern machine learning and data analysis.
          </p>
          <BlogImageContainer img={img1} alt={"Lasso Regression Equation"} />

          <h3 className="text-xl">
            The Need for Regularisation and Feature Selection
          </h3>
          <p>
            As mentioned in the Ridge Regression blog, linear regression can
            encounter issues with multicollinearity and overfitting when the
            number of features is high. Multicollinearity occurs when
            independent variables are highly correlated, leading to unstable and
            unreliable coefficient estimates. Overfitting, on the other hand,
            arises when the model becomes too complex and captures noise rather
            than the true underlying patterns in the data.
          </p>

          <p className="py-2">
            Lasso Regression, similar to Ridge Regression, employs a
            regularisation term (α) to prevent overfitting. However, Lasso takes
            it one step further by introducing the L1 norm (absolute values) of
            the coefficient vector in the error function. The L1 regularisation
            term is multiplied by α, as shown in the equation above. The L1
            regularisation penalty effectively shrinks some coefficients to
            zero, leading to feature selection. This means that Lasso Regression
            can zero out irrelevant or redundant features, offering a sparse
            model with only the most informative predictors.
          </p>
          <h3 className="text-xl pt-4">Solving Lasso Regression</h3>
          <p>
            Unlike Ridge Regression, you cant use the ordinary least squares (OLS) method
            is used to find the coefficients that minimise the error function.
            However, in Lasso Regression, the additional L1 regularisation term
            must also be considered. The goal is to find the coefficient values
            (β) that minimise the sum of squared errors and the sum of the
            absolute values of the coefficients, as shown in the equation below:
          </p>
          <BlogImageContainer img={img2} alt={""} />
          <p>Expanding the brackets gives us:</p>
          <BlogImageContainer img={img3} />
          <p>
            The issue is that the regularisation term can not be taken out as the differential for 
            an absolute value is the absolute value divided by the value you make it impossible to factor out
            β when you make the equation equal to 0
          </p>
          <BlogImageContainer img={img4} alt={""} />
          <p>
            This means that you would have to a optimisation technique to find the β vector like gradient descent. Now many 
            libraries use Coordinate Descent or LARS to help with finding coefficents
          </p>
          <BlogImageContainer img={img5} />
          <h3 className="text-xl pt-4">
            Choosing the Regularisation Parameter (α)
          </h3>
          <p>
            Similar to Ridge Regression, the choice of the regularisation
            parameter (α) in Lasso Regression is crucial. The value of α
            controls the amount of shrinkage applied to the coefficients and,
            consequently, the number of features that are retained in the model.
            A large α value results in more coefficients being set to zero,
            leading to a simpler model with fewer features. Conversely, a small
            α value allows more coefficients to remain nonzero, potentially
            leading to overfitting.
          </p>
          <p>
            Cross-validation techniques can be used to determine the optimal α
            value that strikes the right balance between bias and variance,
            ultimately producing a model that generalises well on unseen data.
          </p>

          <h3 className="text-xl pt-4">Conclusion</h3>
          <p>
            Lasso Regression is a powerful extension of linear regression that
            offers a solution to feature selection and overfitting in
            high-dimensional datasets. By introducing the L1 regularisation
            term, Lasso Regression can shrink coefficients to zero, effectively
            excluding irrelevant features and producing a sparse model. This
            enhances the model's interpretability and generalisability. Lasso
            Regression is commonly used in various domains, such as genetics,
            economics, and signal processing, where selecting the most relevant
            features is of paramount importance. By understanding Lasso
            Regression and its impact on model complexity, data scientists and
            analysts can make informed decisions when building predictive models
            for real-world applications.
          </p>
        </div>
      </div>
    </div>
  );
}
