import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneLight } from "react-syntax-highlighter/dist/esm/styles/prism";
import DifficultyButtonContainer from "../../../../components/Buttons/DifficultyButtonContainer";
export default function DpScaling() {
  return (
    <div class="w-full w-max-600 my-4 bg-white">
      <div class="flex justify-center flex-col px-4">
        <div class="flex justify-between items-start">
          <div>
            <h1 class="text-3xl">Scaling Data - Data Processing</h1>
            <h2 class="text-2xl">Making Data Easier to Digest</h2>
          </div>
          <div class="px-2 flex items-start justify-start"><DifficultyButtonContainer Level={"Easy"} /></div>
        </div>
        <div>
          <h3 class="text-xl pt-3">Introduction</h3>
          <p>
            Data preprocessing is a crucial step in the machine learning
            pipeline, where the raw data is transformed and prepared for
            training models. One important aspect of data preprocessing is
            scaling, which involves transforming features to a common scale to
            ensure better model performance and convergence. In this article, we
            will explore different methods of scaling data, with a particular
            focus on the widely used StandardScaler technique.
          </p>
          <h3 class="text-xl pt-3">
            What is Scaling Data and Why is it Important?
          </h3>
          <p>
            Scaling data refers to the process of transforming numerical
            features to a common scale without distorting their original
            distributions. Many machine learning algorithms are sensitive to the
            scale of the input features. When features have widely different
            ranges, algorithms that rely on distance metrics or gradient-based
            optimization methods can be affected. Scaling data is essential to
            ensure that no particular feature dominates the learning process,
            leading to more stable and accurate model predictions.
          </p>

          <h3 class="text-xl pt-3">
            StandardScaler - A Widely Used Scaling Technique
          </h3>
          <p>
            StandardScaler is a popular method for scaling data. I uses the
            normal distribution you would have learnt at high school to scale
            the data. It standardises features by removing the mean and scaling
            them to unit variance. The formula for standardisation is as
            follows:
          </p>

          <p class="text-center">
            <strong>Z = (X - μ) / σ</strong>
          </p>

          <p>
            Where:
            <ul class="list-disc pl-8">
              <li>
                <em>Z</em> is the standardised value.
              </li>
              <li>
                <em>X</em> is the original value of the feature.
              </li>
              <li>
                <em>μ</em> is the mean of the feature.
              </li>
              <li>
                <em>σ</em> is the standard deviation of the feature.
              </li>
            </ul>
          </p>

          <p>
            StandardScaler is widely used because it ensures that the scaled
            features have a mean of 0 and a standard deviation of 1. This
            transformation centres the features around zero, which can be
            particularly useful for algorithms that rely on symmetrically
            distributed data. It also preserves the shape of the original
            distributions, making it a safe choice for many machine learning
            tasks.
          </p>

          <h3 class="text-xl pt-3">
            Python Code Example: Scaling Data with StandardScaler
          </h3>
          <SyntaxHighlighter language="python" style={oneLight}>
            {`import pandas as pd
import numpy as np

from sklearn.preprocessing import StandardScaler

def feature_scaling(df):
    X = df.iloc[:,:-1]
    y = df.iloc[:, -1]

    sc = StandardScaler()

    X.iloc[:, :3] = sc.fit_transform(df.iloc[:, :3])

    print(X.values)

    return X, y
`}
          </SyntaxHighlighter>
          <p>
            In the example above, we used the <b>StandardScaler</b> from
            the <b>Sklearn</b> to scale the numerical
            features in our dataset. The transformed data now has a mean of
            approximately 0 and a standard deviation of approximately 1,
            ensuring that all features are on the same scale.
          </p>

          <h3 class="text-xl pt-3">Other Scaling Techniques</h3>
          <p>
            While StandardScaler is a reliable method for scaling data, it is
            not the only option available. Depending on the characteristics of
            the data and the specific machine learning algorithm being used,
            other scaling techniques may be more suitable. Here are a few other
            commonly used scaling methods:
          </p>

          <ul class="list-disc pl-8">
            <li>
              <strong>MinMaxScaler:</strong> Scales features to a specified
              range, usually between 0 and 1.
            </li>
            <li>
              <strong>RobustScaler:</strong> Scales features using median and
              quartiles, making it robust to outliers kind of like a box plot
              the scaler only cares about what is inside the box. It is useful
              when the data contains extreme values that could affect standard
              scaling.
            </li>
          </ul>

          <h3 class="text-xl pt-3">Conclusion</h3>
          <p>
            Scaling data is a crucial preprocessing step in machine learning. It
            ensures that features are on a consistent scale, preventing any
            particular feature from dominating the learning process.
            StandardScaler is a widely used and effective method for scaling
            data, but it is essential to consider other scaling techniques
            depending on the nature of the data and the machine learning
            algorithm being used.
          </p>
          <p>
            In summary, by scaling data appropriately, you can enhance the
            performance, stability, and convergence of your machine learning
            models, leading to more accurate and reliable predictions.
          </p>
        </div>
      </div>
    </div>
  );
}
